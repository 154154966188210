
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "../../store/enums/StoreEnums";
import { getIllustrationsPath } from "../../core/helpers/assets";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "journal",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageTitle("Journal");

    });

    return {
      getIllustrationsPath,
    };

  },
});
