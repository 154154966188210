import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createBlock(_component_el_alert, {
    style: {"margin-top":"-35px"},
    title: "Coming soon!",
    type: "warning",
    "show-icon": "",
    closable: false
  }))
}